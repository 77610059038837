import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt } from '@fortawesome/pro-duotone-svg-icons'

export default function GearScoreBadge ({ gearScore }) {
  return (
    <div className="bg-failure text-white font-bold text-xs leading-0 px-1 py-[1px] rounded-sm flex space-x-1 items-center">
      <FontAwesomeIcon
        icon={faShieldAlt}
        className="w-2.5 h-2.5 text-white"
      />
      <span>
        {gearScore}
      </span>
    </div>
  )
}
