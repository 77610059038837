import { useDispatch, useSelector } from 'react-redux'
import { setBagOpen } from 'redux/professions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackpack } from '@fortawesome/pro-duotone-svg-icons'

export default function BagToggle () {
  const dispatch = useDispatch()
  const bagOpen = useSelector(store => store.professions.bagOpen)

  const handleOpen = () => {
    dispatch(setBagOpen(!bagOpen))
  }

  return (
    <button
      type="button"
      onClick={handleOpen}
      className="bg-dark rounded p-3"
    >
      <FontAwesomeIcon
        icon={faBackpack}
        className="w-6 h-6 text-white hover:text-primary"
      />
    </button>
  )
}
