import ItemIcon from 'components/professions/ItemIcon'

export default function BagSlot ({ userItem }) {
  const quantity = userItem.item.type === 'fail_stack' ? userItem?.durability : userItem.quantity

  return (
    <div className={'w-full relative block'}>
      <ItemIcon
        item={userItem.item}
        userItem={userItem}
        quantity={quantity}
      />
    </div>
  )
}
