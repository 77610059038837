import Stat from 'components/stats/Stat'
import { twMerge } from 'tailwind-merge'

export default function UserItemStats ({ userItem }) {
  if (!userItem.final_stats) {
    return <></>
  }

  return (
    <div className={twMerge('font-bold text-white')}>
      {userItem.stats?.buffs?.secondary && (
        <div>
          +
          {Math.round((userItem.stats.buffs.secondary - 1) * 100)}
          % secondary stats
        </div>
      )}

      {userItem.final_stats.map((stat) => {
        return <div key={`stag_slug_${userItem.id}_${stat.stat.slug}`}>
          {stat.value > 0
            ? (
              <Stat
                stat={stat}
                boosted={stat.stat.type === 'secondary' && userItem.secondary_buff}
                boostText="Increased by Godrune"
              />
              )
            : <></>}
        </div>
      })}

      {userItem.durability === 0 && (
        <div className="text-failure">
          This item is broken and lost all its stats. Repair it!
        </div>
      )}
    </div>
  )
}
