import { useEffect, useRef, useState } from 'react'
import Button from '@atoms/Button'
import RaidOverlay from 'components/raids/RaidOverlay'
import Window from 'components/layout/Window'
import Crafting from 'components/raids/Crafting'
import { getUserItems } from 'helpers/bags'
import { useDispatch } from 'react-redux'
import ItemIcon from 'components/professions/ItemIcon'
import ProgressBar from '@atoms/ProgressBar'
import { getGodruneUpgradeCost, upgradeGodrune } from 'helpers/professions'
import { handleError } from 'helpers/raidsTx'
import CostBox from 'components/layout/elements/CostBox'
import { formatNumber } from 'accounting'
import FragmentsCurrency from 'components/layout/elements/FragmentsCurrency'
import { getQuantityInUserItemList, removeUserItemsFromListByItemId } from 'helpers/items'
import { twMerge } from 'tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faQuestionCircle, faUp } from '@fortawesome/pro-duotone-svg-icons'
import { Tooltip } from 'react-tippy'
import { setAuthUser } from 'store'
import { getRarityStylesByRarityLabel } from 'consts/rarity'

export default function GodruneDetail ({ userItem, setUserItem, onUpgraded, onRaiderUpdated, onClose }) {
  const dispatch = useDispatch()
  const [userItems, setUserItems] = useState([])
  const [crafting, setCrafting] = useState(null)
  const [cost, setCost] = useState(null)
  const [progressUpdate, setProgressUpdate] = useState(null)
  const progress = userItem.progress ?? 0
  const powerCoresInBag = cost && cost.power_core ? getQuantityInUserItemList(userItems, cost.power_core.id) : 0
  const canUpgrade = userItem.item.rarity !== 'Mythic'

  // Ref to track the timeout for resetting progressUpdate
  const progressUpdateTimeoutRef = useRef(null)

  const fetchItems = async () => {
    try {
      const data = await getUserItems({
        types: ['resource']
      })
      setUserItems(data.items)
    } catch (e) {}
    setCrafting(null)
  }

  const getCost = async () => {
    try {
      const data = await getGodruneUpgradeCost({
        userItemId: userItem.id
      })
      if (data.cost) {
        setCost(data.cost)
      }
    } catch (e) {
      handleError(e, dispatch)
    }
  }

  const doUpgrade = async () => {
    try {
      const data = await upgradeGodrune({
        userItemId: userItem.id
      })
      if (data.user) {
        dispatch(setAuthUser(data.user))
      }
      if (data.user_item) {
        setUserItem(data.user_item)
      }
      if (data.progress && data.progress_rarity) {
        setProgressUpdate({
          progress: data.progress,
          progress_rarity: data.progress_rarity,
          upgraded: data.upgraded
        })
      }
      if (cost.power_cores && cost.power_core) {
        setUserItems(removeUserItemsFromListByItemId(userItems, { [cost.power_core.id]: cost.power_cores }))
      }
      if (onRaiderUpdated) {
        onRaiderUpdated(data)
      }
      if (data.upgraded && onUpgraded) {
        onUpgraded()
      }
      getCost()
    } catch (e) {
      handleError(e, dispatch)
    }
  }

  useEffect(() => {
    if (progressUpdate) {
      // Clear any existing timeout
      if (progressUpdateTimeoutRef.current) {
        clearTimeout(progressUpdateTimeoutRef.current)
      }

      // Set a new timeout to reset progressUpdate
      progressUpdateTimeoutRef.current = setTimeout(() => {
        setProgressUpdate(null)
        progressUpdateTimeoutRef.current = null // Reset ref
      }, 3000)
    }

    // Cleanup timeout on unmount
    return () => {
      if (progressUpdateTimeoutRef.current) {
        clearTimeout(progressUpdateTimeoutRef.current)
      }
    }
  }, [progressUpdate])

  useEffect(() => {
    setCrafting('Loading items')
    getCost()
    fetchItems()
  }, [])

  return (
    <RaidOverlay onClose={() => { onClose() }}>
      <Window
        className="max-w-[320px]"
        actions={<>
          <Button
            onClick={onClose}
            className="text-raids-text uppercase text-sm"
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className={'w-4 h-4 mr-2 text-white'}
            />
            Cancel
          </Button>
          {canUpgrade && (
            <div className="ml-auto flex space-x-2 items-center">
              <Tooltip
                html={<>
                  <div className="mb-2">
                    The amount of progress is based on RNG:
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-center max-w-[160px] mx-auto">
                    <div className="font-bold">
                      Chance
                    </div>
                    <div className="font-bold">
                      Points
                    </div>
                    <div className="text-common">
                      49%
                    </div>
                    <div>
                      30
                    </div>
                    <div className="text-uncommon">
                      25%
                    </div>
                    <div>
                      40
                    </div>
                    <div className="text-rare">
                      15%
                    </div>
                    <div>
                      50
                    </div>
                    <div className="text-epic">
                      8%
                    </div>
                    <div>
                      70
                    </div>
                    <div className="text-legendary">
                      3%
                    </div>
                    <div>
                      100
                    </div>
                  </div>
                </>}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="w-4 h-4 ml-1.5 text-white"
                />
              </Tooltip>
              <Button
                onClick={doUpgrade}
                raids
              >
                Progress
              </Button>
            </div>
          )}
        </>}
      >
        {crafting && <Crafting message={crafting} />}

        <div className="text-center font-semibold text-lg mb-6">
          Upgrade Godrune
        </div>

        <div className="flex justify-center">
          <div className="w-20">
            <ItemIcon
              userItem={userItem}
              item={userItem.item}
            />
          </div>
        </div>
        <div className="mt-6">
          {!canUpgrade && (
            <span className="text-primary italic">
              This Godrune has been fully upgraded!
            </span>
          )}

          {canUpgrade && (
            <div className="text-center relative">
              {progressUpdate && progressUpdate.upgraded && (
                <div
                  className="absolute pointer-events-none left-0 top-0 flex space-x-1 items-center"
                >
                  <FontAwesomeIcon
                    icon={faUp}
                    className="w-3 h-3 text-success"
                  />
                  <span className="animate-bounce text-lg">
                    🎉
                  </span>
                </div>
              )}
              {progressUpdate && (
                <div
                  className="absolute pointer-events-none right-0 top-0"
                  style={getRarityStylesByRarityLabel(progressUpdate.progress_rarity).text}
                >
                  +
                  {progressUpdate.progress}
                  %
                </div>
              )}

              <div className="text-sm mb-2">
                Upgrade progress
              </div>

              <div className="mb-6">
                <ProgressBar
                  barStart={0}
                  barCurrent={progress}
                  barEnd={100}
                  barClass={'rounded'}
                  colors={{
                    text: 'text-primary',
                    bg: 'bg-primary'
                  }}
                />
              </div>

              {cost && (
                <>
                  <CostBox label="Progress cost">
                    <div className="text-2xl">
                      {formatNumber(cost.fragments, 0)}
                    </div>
                    <FragmentsCurrency />
                  </CostBox>
                  <CostBox>
                    <div className={twMerge('text-xl', powerCoresInBag < cost.power_cores ? 'text-failure' : '')}>
                      {cost.power_cores}
                      /
                      <span className="text-base text-raids-text">
                        {powerCoresInBag}
                      </span>
                    </div>
                    <div className="flex items-center grow-0 ml-auto">
                      <span className="block leading-none mb-1 mr-1 text-sm">
                        {cost.power_core.name}
                      </span>
                      <div className="w-12 h-12">
                        <ItemIcon item={cost.power_core} />
                      </div>
                    </div>
                  </CostBox>
                </>
              )}
            </div>
          )}
        </div>
      </Window>
    </RaidOverlay>
  )
}
