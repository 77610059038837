import { useDispatch } from 'react-redux'
import BagSlot from './BagSlot'
import Box from 'components/layout/elements/Box'
import { useEffect, useState } from 'react'
import { useAccountWideBuffConsumableItem } from 'helpers/consumables'
import { setAuthUser } from 'store'
import Button from '@atoms/Button'
import RaidOverlay from 'components/raids/RaidOverlay'
import Window from 'components/layout/Window'
import { handleError } from 'helpers/raidsTx'
import { toast } from 'react-toastify'
import { convertEssenceFlakes } from 'helpers/professions'
import Crafting from 'components/raids/Crafting'
import CostBox from 'components/layout/elements/CostBox'
import { getUserBagSize, getUserItems } from 'helpers/bags'
import { removeUserItemsFromList, removeUserItemsFromListByItemId, updateUserItemInList } from 'helpers/items'
import useAuth from 'hooks/useAuth'
import { twMerge } from 'tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tippy'
import { faWeightHanging } from '@fortawesome/pro-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons'
import GodruneDetail from 'components/godrune/GodruneDetail'

export default function Bag ({ handleClose = null }) {
  const dispatch = useDispatch()
  const [itemType, setItemType] = useState('resource')
  const [bagSize, setBagSize] = useState(1)
  const [userItems, setUserItems] = useState([])
  const [confirmUseItem, setConfirmUseItem] = useState(null)
  const [useQuantity, setUseQuantity] = useState(1)
  const [upgradeGodruneUserItem, setUpgradeGodruneUserItem] = useState(null)
  const [crafting, setCrafting] = useState(null)
  const user = useAuth(async () => {
    try {
      const data = await getUserBagSize()
      setBagSize(data?.bag_size ?? 1)
    } catch (e) {}
  })
  const maxBagSize = user?.bag_size ?? 33
  const bagFull = bagSize >= maxBagSize
  const bagAlmostFull = bagSize >= (maxBagSize * 0.9)

  const useConsumable = async () => {
    setCrafting('Using consumable...')

    try {
      const data = await useAccountWideBuffConsumableItem({
        userItemId: confirmUseItem.id,
        quantity: useQuantity
      })
      setConfirmUseItem(null)
      if (data.user) {
        dispatch(setAuthUser(data.user))
        setUserItems(removeUserItemsFromList(userItems, { [confirmUseItem.id]: useQuantity }))
        toast.success(`Successfully used ${confirmUseItem.item.name}`)
      }
    } catch (e) {
      handleError(e, dispatch)
    }
    setCrafting(null)
  }

  const doConvertEssenceFlakes = async () => {
    setCrafting('Converting Essence Flakes...')
    try {
      const data = await convertEssenceFlakes()
      let updatedUserItems = userItems
      if (data.essence_flake_item_id) {
        updatedUserItems = removeUserItemsFromListByItemId(userItems, { [data.essence_flake_item_id]: 100 })
      }
      if (data.essence_shard_user_item) {
        updatedUserItems = updateUserItemInList(userItems, data.essence_shard_user_item)
      }
      setUserItems(updatedUserItems)
    } catch (e) {
      handleError(e, dispatch)
    }
    setCrafting(null)
  }

  const fetchUserItems = async () => {
    setCrafting('Searching bag')
    try {
      const data = await getUserItems({
        types: [itemType]
      })
      setUserItems(data.items)
      setCrafting(null)
    } catch (e) {
      handleError(e, dispatch)
      setCrafting(null)
    }
  }

  useEffect(() => {
    fetchUserItems()
  }, [itemType])

  return (
    <>
      <Box
        className="w-[400px] max-w-full"
        header={
          <>
            <div className="w-full flex px-1.5 space-x-3">
              <button
                type="button"
                onClick={() => { setItemType('resource') }}
                className={itemType === 'resource' ? 'text-primary' : ''}
              >
                Resources
              </button>
              <button
                type="button"
                onClick={() => { setItemType('consumable') }}
                className={itemType === 'consumable' ? 'text-primary' : ''}
              >
                Consumables
              </button>
              <button
                type="button"
                onClick={() => { setItemType('gear') }}
                className={itemType === 'gear' ? 'text-primary' : ''}
              >
                Gear
              </button>
            </div>
          </>
          }
        actions={
          <div className='ml-auto'>
            <Tooltip
              html={<>
                <strong>
                  Bag weight
                </strong>
                {bagFull && (<>
                  <br/>
                  <span className="text-failure">
                    Your bags are full. You cannot start raids, salvage items or purchase items.
                  </span>
                </>)}
                {!bagFull && bagAlmostFull && (<>
                  <br/>
                  <span className="text-warning">
                    Your bags are almost full. When your bag is full you cannot start raids, salvage items or purchase items.
                  </span>
                </>)}
              </>}
            >
              <div className="flex space-x-1 items-center">
                <FontAwesomeIcon
                  icon={faWeightHanging}
                  className={twMerge('w-3 h-3', bagFull ? 'text-failure' : (bagAlmostFull ? 'text-warning' : ''))}
                />
                <span>
                  <span className={bagFull ? 'text-failure' : (bagAlmostFull ? 'text-warning' : '')}>
                    {Math.round(bagSize)}
                  </span>
                  <span className="text-sm text-raids-text">
                    {' '}
                    /
                    {' '}
                    {maxBagSize}
                  </span>
                </span>
              </div>
            </Tooltip>
          </div>}
        onClose={handleClose}
      >
        {crafting && <Crafting message={crafting} />}
        <div className='p-3 h-[300px] overflow-y-auto'>
          <div className="w-full grid grid-cols-5 gap-2">
            {userItems.map((userItem) => {
              return (
                <button
                  type="button"
                  key={`bag_slot_${userItem.id}`}
                  onClick={() => {
                    if (userItem.item.type === 'consumable' && userItem.item.consumable_item.usage === 'account-wide-buff') {
                      setUseQuantity(1)
                      setConfirmUseItem(userItem)
                    } else if (userItem.item.slug === 'essence-flake') {
                      doConvertEssenceFlakes()
                    } else if (userItem.item.type === 'gear' && userItem.item?.gear_item?.slot === 'godrune') {
                      setUpgradeGodruneUserItem(userItem)
                    }
                  }}
                >
                  <span className={'w-full rounded-sm bg-tertiary block text-white relative'}>
                    <BagSlot
                      userItem={userItem}
                    />
                  </span>
                </button>
              )
            })}
          </div>
        </div>
      </Box>

      {confirmUseItem && (
        <RaidOverlay>
          <Window
            actions={<>
              <Button
                onClick={() => { setConfirmUseItem(null) }}
                className="text-raids-text uppercase text-sm"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className={'w-4 h-4 mr-2 text-white'}
                />
                Cancel
              </Button>
              <Button
                onClick={useConsumable}
                className="ml-auto"
                raids
              >
                Use item
              </Button>
            </>}
          >
            <div className="max-w-[340px]">
              <div className="text-center font-semibold text-lg mt-6">
                Are you sure you want to use
                <br/>
                {confirmUseItem.item.name}
                ?
              </div>
              <div className="p-2 border border-primary rounded mt-8 mb-4">
                {confirmUseItem.item.description}
              </div>
              <CostBox>
                <div className="text-sm text-raids-text uppercase">
                  How many to use?
                </div>
                <div className="ml-auto">
                  <input
                    className="py-2 px-3 bg-white rounded border-none text-lg font-bold w-[100px] text-black"
                    type="number"
                    name="quantity"
                    min="1"
                    max={confirmUseItem.quantity}
                    value={useQuantity}
                    onChange={e => setUseQuantity(e.target.value)}
                  />
                </div>
              </CostBox>
            </div>
          </Window>
        </RaidOverlay>
      )}

      {upgradeGodruneUserItem && (
        <GodruneDetail
          userItem={upgradeGodruneUserItem}
          setUserItem={setUpgradeGodruneUserItem}
          onClose={() => { setUpgradeGodruneUserItem(null) }}
          onUpgraded={fetchUserItems}
        />
      )}
    </>
  )
}
