import { faUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatNumber } from 'accounting'
import { Tooltip } from 'react-tippy'

export default function Stat ({ stat, boosted, boostText }) {
  return (
    <div className="flex space-x-0.5 items-center">
      <span>
        +
        {formatNumber(stat.value, { precision: 1 }).replace(/\.0$/, '')}
        {stat.stat.type === 'secondary' ? '%' : ''}
        {' '}
        {stat.stat.name}
      </span>
      {boosted && (
        <Tooltip title={boostText}>
          <FontAwesomeIcon
            icon={faUp}
            className="w-3 h-3 text-success"
          />
        </Tooltip>
      )}
    </div>
  )
}
