export function getItemTypeLabel (item) {
  if (item.type === 'consumable') {
    return 'Consumable'
  }
  if (item.type === 'fail_stack') {
    return 'Fail stack'
  }
  if (item.type === 'resource') {
    return 'Resource'
  }
  if (item.type === 'recipe') {
    return 'Recipe'
  }

  return 'Gear'
}

export function getGearItemSlotLabel (slot) {
  if (slot === 'special') {
    return 'Trinket'
  }

  return slot
}

export function getEnchantingLevelLabel (category, level) {
  if (category === 'A') {
    if (level === 11) {
      return 'PRI'
    } else if (level === 12) {
      return 'DUO'
    } else if (level === 13) {
      return 'TRI'
    } else if (level === 14) {
      return 'TET'
    } else if (level === 15) {
      return 'PEN'
    }
  }

  if (category === 'B') {
    if (level === 1) {
      return 'PRI'
    } else if (level === 2) {
      return 'DUO'
    } else if (level === 3) {
      return 'TRI'
    } else if (level === 4) {
      return 'TET'
    } else if (level === 5) {
      return 'PEN'
    }
  }

  return level
}

export function removeUserItemsFromList (userItems, quantities) {
  for (const [userItemId, quantityToRemove] of Object.entries(quantities)) {
    const itemIndex = userItems.findIndex(item => item.id === parseInt(userItemId))

    if (itemIndex === -1) continue

    userItems[itemIndex].quantity -= quantityToRemove

    if (userItems[itemIndex].quantity <= 0) {
      userItems.splice(itemIndex, 1)
    }
  }

  return userItems
}

export function removeUserItemsFromListByItemId (userItems, quantities) {
  for (const [userItemId, quantityToRemove] of Object.entries(quantities)) {
    const itemIndex = userItems.findIndex(item => item.item.id === parseInt(userItemId))

    if (itemIndex === -1) continue

    userItems[itemIndex].quantity -= quantityToRemove

    if (userItems[itemIndex].quantity <= 0) {
      userItems.splice(itemIndex, 1)
    }
  }

  return userItems
}

export function addUserItemsToList (userItems, userItemsToAdd) {
  for (const userItemToAdd of userItemsToAdd) {
    if (userItemToAdd.item.unique || !userItems.find(u => u.item.id === userItemToAdd.item.id)) {
      userItems.push(userItemToAdd)
    } else {
      userItems = userItems.map(u => u.item.id === userItemToAdd.item.id ? { ...u, quantity: u.quantity + userItemToAdd.quantity } : u)
    }
  }

  return [...userItems]
}

export function updateUserItemInList (userItems, userItem) {
  return [...userItems.map(u => u.id === userItem.id ? userItem : u)]
}

export function getQuantityInUserItemList (userItems, itemId) {
  if (!userItems || !userItems.length) {
    return 0
  }

  return userItems.reduce((sum, userItem) => {
    if (userItem.item.id === itemId) {
      return sum + userItem.quantity
    }
    return sum
  }, 0)
}

export function sortByOwned (a, b) {
  if (a.not_owned && !b.not_owned) {
    return 1
  } else if (!a.not_owned && b.not_owned) {
    return -1
  } else {
    return 0
  }
}
