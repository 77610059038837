export default function FragmentsCurrency () {
  return (
    <div className="flex items-center grow-0 ml-auto text-left">
      <img
        src="/tokens/wyrmstone-fragment@200.png"
        width="50"
        height="50"
        alt="Fragments"
        className="mr-2"
      />
      <span>
        <span className="text-lg block leading-none mb-1">
          Fragments
        </span>
        <span className="text-raids-text text-xs block leading-none">
          Off-Chain
        </span>
      </span>
    </div>
  )
}
