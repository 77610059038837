import { Tooltip } from 'react-tippy'
import QuantityOverlay from './QuantityOverlay'
import UserItemTooltip from './items/UserItemTooltip'
import ItemTooltip from './items/ItemTooltip'
import { getRarityStylesByRarityLabel } from 'consts/rarity'
import { getEnchantingLevelLabel } from 'helpers/items'
import { twMerge } from 'tailwind-merge'

export default function ItemIcon ({ item, quantity, ownedQuantity, userItem = null, disableTooltip = false }) {
  const rarityStyle = getRarityStylesByRarityLabel(item.rarity)
  const isGearItem = item?.type === 'gear'

  const getImage = () => {
    if (item.image) {
      return `/items/icons/${item.image}.webp`
    }
    if (item.type === 'resource' && item.resource_item.base_material) {
      return '/professions/placeholders/raw-resource-item.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'weapon') {
      return '/professions/placeholders/weapon.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'special') {
      return '/professions/placeholders/tool.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'ring') {
      return '/professions/placeholders/ring.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'relic') {
      return '/professions/placeholders/necklace.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'armor') {
      return '/professions/placeholders/armor-gear-item.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'shoulders') {
      return '/professions/placeholders/shoulders-gear-item.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'head') {
      return '/professions/placeholders/head-gear-item.png'
    }
    if (item.type === 'gear') {
      return '/professions/placeholders/gauntlets-gear-item.png'
    }
    if (item.type === 'recipe') {
      return `/items/icons/${item.crafted_item.image}.webp`
    }

    return '/professions/placeholders/resource-item.png'
  }

  const getTooltip = () => {
    if (disableTooltip) {
      return <></>
    }

    if (userItem) {
      return <UserItemTooltip userItem={userItem} />
    }

    return <ItemTooltip item={item} />
  }

  return (
    <span className="w-full relative block">
      <Tooltip
        html={getTooltip()}
        disabled={disableTooltip}
      >
        <img
          src={getImage()}
          alt={item.name}
          className={'w-full block rounded-sm'}
        />
      </Tooltip>

      {userItem && isGearItem && userItem.enchanting_category && (
        <>
          <div className={twMerge('min-w-3 h-3 font-bold text-xs flex justify-center items-center rounded-br-sm rounded-tl-sm absolute top-0 left-0', userItem.enchanting_category === 'A' ? 'bg-white text-black' : 'bg-black text-white')}>
            {getEnchantingLevelLabel(userItem.enchanting_category, userItem.enchanting_level)}
          </div>
        </>
      )}

      {userItem && isGearItem && userItem?.durability === 0 && (
        <>
          <div className="absolute min-w-2.5 h-2.5 rounded-bl-sm rounded-tr-sm top-0 right-0 bg-failure"></div>
        </>
      )}

      {userItem && isGearItem && userItem?.durability > 0 && userItem?.durability < 100 && (
        <>
          <div className="absolute min-w-2.5 h-2.5 rounded-bl-sm rounded-tr-sm top-0 right-0 bg-primary"></div>
        </>
      )}

      <div
        className="absolute min-w-2.5 h-2.5 rounded-bl-sm rounded-tr-sm bottom-0 left-0"
        style={rarityStyle.block}
      ></div>

      {quantity && (
        <QuantityOverlay
          quantity={quantity}
          ownedQuantity={ownedQuantity}
        />
      )}
    </span>
  )
}
