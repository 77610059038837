export default function ItemStatPreview ({ item }) {
  if (!item.stat_preview) {
    return <></>
  }

  const stats = item.stat_preview
  const hasguaranteedEfficiencyStats = !!(stats.efficiency && stats.efficiency.guaranteed && stats.efficiency.guaranteed.length)
  const hasOptionalEfficiencyStats = !!(stats.efficiency && stats.efficiency.optional && stats.efficiency.optional.length)
  const hasguaranteedSecondaryStats = !!(stats.secondary && stats.secondary.guaranteed && stats.secondary.guaranteed.length)
  const hasOptionalSecondaryStats = !!(stats.secondary && stats.secondary.optional && stats.secondary.optional.length)

  return (
    <div className='font-bold text-white'>
      {stats.core && (
        <div>
          +
          {stats.core.value}
          {' '}
          {stats.core.guaranteed.join(' / ')}
        </div>
      )}
      {stats.efficiency && (
        <div>
          +
          {stats.efficiency.value}
          {' '}
          {hasguaranteedEfficiencyStats && stats.efficiency.guaranteed.length > 1 && '('}
          {hasguaranteedEfficiencyStats ? stats.efficiency.guaranteed.join(' or ') : ''}
          {hasguaranteedEfficiencyStats && stats.efficiency.guaranteed.length > 1 && ')'}
          {hasguaranteedEfficiencyStats && hasOptionalEfficiencyStats ? ' and' : ''}
          {' '}
          {hasOptionalEfficiencyStats && stats.efficiency.optional.length > 1 && '['}
          {hasOptionalEfficiencyStats ? stats.efficiency.optional.join(' or ') : ''}
          {hasOptionalEfficiencyStats && stats.efficiency.optional.length > 1 && ']'}
        </div>
      )}
      {stats.secondary && (
        <div>
          +
          {stats.secondary.value}
          {' '}
          {hasguaranteedSecondaryStats && stats.secondary.guaranteed.length > 1 && '('}
          {hasguaranteedSecondaryStats ? stats.secondary.guaranteed.join(' or ') : ''}
          {hasguaranteedSecondaryStats && hasOptionalSecondaryStats ? ' and' : ''}
          {hasguaranteedSecondaryStats && stats.secondary.guaranteed.length > 1 && ')'}
          {' '}
          {hasOptionalSecondaryStats && stats.secondary.optional.length > 1 && '['}
          {hasOptionalSecondaryStats ? stats.secondary.optional.join(' or ') : ''}
          {hasOptionalSecondaryStats && stats.secondary.optional.length > 1 && ']'}
        </div>
      )}
      {stats?.buffs?.secondary && (
        <div>
          +
          {Math.round((stats.buffs.secondary - 1) * 100)}
          % secondary stats
        </div>
      )}
    </div>
  )
}
