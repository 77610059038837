import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

export default function UserName ({ user, iconSize }) {
  const image = user.icon_image && user.icon_label
    ? (
      <Tooltip title={user.icon_label}>
        <img
          src={user.icon_image}
          alt={user.icon_label}
          onError={event => { event.target.style.visibility = 'hidden' }}
          width={iconSize}
          height={iconSize}
          className="ml-1 w-auto"
          style={{
            height: iconSize
          }}
        />
      </Tooltip>
      )
    : null

  return (
    <span
      className={'flex items-center'}
      style={{
        color: user.name_color ?? '#FFFFFF'
      }}
    >
      {user.name}
      {image}
    </span>
  )
}

UserName.propTypes = {
  user: PropTypes.object.isRequired,
  iconSize: PropTypes.number
}

UserName.defaultProps = {
  user: false,
  iconSize: 20
}
