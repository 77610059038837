import axios from 'helpers/api'

export async function getUserBagSize () {
  return await axios.get('/api/bags/me/size').then(({ data }) => {
    return data
  })
}

export async function getUserFailStackSlots () {
  return await axios.get('/api/bags/me/fail-stack-slots').then(({ data }) => {
    return data
  })
}

export async function getUserItems ({
  tradable = null,
  dustable = null,
  sellable = null,
  rarities = null,
  slugs = null,
  types = null,
  slots = null,
  minDur = null
}) {
  let query = ''
  if (tradable !== null) {
    query += 'tradable=' + tradable + '&'
  }
  if (dustable !== null) {
    query += 'dustable=' + dustable + '&'
  }
  if (sellable !== null) {
    query += 'sellable=' + sellable + '&'
  }
  if (rarities !== null) {
    query += 'rarities=' + rarities.join(',') + '&'
  }
  if (slugs !== null) {
    query += 'slugs=' + slugs.join(',') + '&'
  }
  if (types !== null) {
    query += 'types=' + types.join(',') + '&'
  }
  if (slots !== null) {
    query += 'slots=' + slots.join(',') + '&'
  }
  if (minDur !== null) {
    query += 'min_dur=' + parseInt(minDur) + '&'
  }

  return await axios.get(`/api/bags/items?${query}page=1`).then(({ data }) => {
    return data
  })
}

export function getQuantityOfItemInBag (bag, itemId) {
  if (!bag) {
    return 0
  }

  let quantity = 0
  for (const itemType in bag) {
    quantity += bag[itemType].slots.reduce((sum, bagSlot) => {
      if (bagSlot.user_item.item.id === itemId) {
        return sum + bagSlot.quantity
      }
      return sum
    }, 0)
  }

  return quantity
}
