import { createAction, createReducer } from '@reduxjs/toolkit'

const initialState = {
  professions: null,
  bagOpen: false,
  config: null
}

export const setProfessions = createAction('professions/setProfessions')
export const setBagOpen = createAction('professions/setBagOpen')
export const setConfig = createAction('professions/setConfig')

export default createReducer(initialState, {
  [setProfessions.type]: (state, { payload }) => ({
    ...state,
    professions: payload
  }),
  [setBagOpen.type]: (state, { payload }) => ({
    ...state,
    bagOpen: payload
  }),
  [setConfig.type]: (state, { payload }) => ({
    ...state,
    config: payload
  })
})
